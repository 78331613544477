import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import '../../App.css';
import { fetchNewsArticles } from '../../services/newsService';
import { NewsSlideshow } from './NewsSlideshow';
import { useTranslation } from '../../contexts/TranslationContext';
import { FiX } from 'react-icons/fi';

interface ResourceSectionProps {
  searchQuery: string;
  isOpen?: boolean;
  onClose?: () => void;
}

interface Resource {
  title: string;
  url: string;
  description: string;
  icon?: string;
}

export const ResourceSection: React.FC<ResourceSectionProps> = ({ searchQuery, isOpen = true, onClose }) => {
  const [news, setNews] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    async function loadNews() {
      const defaultQuery = 'asylum seeker OR immigration';
      const defaultCountry = 'us';
      const defaultCategory = 'politics';

      try {
        const articles = await fetchNewsArticles(defaultQuery, defaultCountry, defaultCategory);
        const titleMap = new Map();
        
        const uniqueArticles = articles.filter((article: { title: string; }) => {
          const normalizedTitle = normalizeTitle(article.title);
          if (titleMap.has(normalizedTitle)) return false;
          titleMap.set(normalizedTitle, true);
          return true;
        });
        
        setNews(uniqueArticles.slice(0, 10));
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    }

    loadNews();
  }, []);

  const normalizeTitle = (title: string): string => 
    title.toLowerCase()
      .replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, "")
      .replace(/\s+/g, " ")
      .trim();

  const immigrationResources: Resource[] = [
    {
      title: t('resources.uscis.title'),
      url: "https://www.uscis.gov/",
      description: t('resources.uscis.description'),
      icon: "🏛️"
    },
    {
      title: t('resources.legal.title'),
      url: "https://www.immigrationadvocates.org/nonprofit/legaldirectory/",
      description: t('resources.legal.description'),
      icon: "⚖️"
    },
    {
      title: t('resources.asylum.title'),
      url: "https://www.uscis.gov/humanitarian/refugees-and-asylum/asylum",
      description: t('resources.asylum.description'),
      icon: "🏠"
    },
    {
      title: t('resources.rights.title'),
      url: "https://www.aclu.org/know-your-rights/immigrants-rights",
      description: t('resources.rights.description'),
      icon: "📋"
    }
  ];

  return (
    <motion.aside 
      initial={false}
      animate={{
        width: isOpen ? "300px" : "60px",
      }}
      transition={{
        duration: 0.3,
        ease: [0.4, 0, 0.2, 1]
      }}
      className="h-full bg-[#1a1a1a] text-white p-4 rounded-2xl overflow-hidden"
    >
      {isOpen && (
        <div className="flex justify-end mb-2">
          <button
            onClick={onClose}
            className="lg:hidden inline-block p-2 rounded-md bg-gray-700 hover:bg-gray-600 transition-colors"
          >
            <FiX />
          </button>
        </div>
      )}

      <h2 className="text-lg font-semibold text-center my-4">{t('news.latest')}</h2>
      <NewsSlideshow 
        news={news} 
        isCollapsed={!isOpen}
        interval={10000}
      />

      {isOpen && (
        <div className="mt-6">
          <h2 className="text-lg font-semibold text-center mb-4">{t('resources.title')}</h2>
          <div className="space-y-3">
            {immigrationResources.map((resource, index) => (
              <motion.a
                key={index}
                href={resource.url}
                target="_blank"
                rel="noopener noreferrer"
                className="block p-3 bg-[#21242b] rounded-lg shadow-md hover:bg-gray-700 transition-colors"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                <div className="flex items-start gap-3">
                  <span className="text-xl">{resource.icon}</span>
                  <div>
                    <h3 className="font-medium hover:text-blue-600">
                      {resource.title}
                    </h3>
                    <p className="text-sm text-gray-400 mt-1">
                      {resource.description}
                    </p>
                  </div>
                </div>
              </motion.a>
            ))}
          </div>
        </div>
      )}
    </motion.aside>
  );
};