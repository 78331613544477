import Box from '@mui/material/Box';
import { keyframes } from '@mui/system';

// Create a shimmer animation
const shimmer = keyframes`
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
`;

export default function CustomSkeleton() {
  return (
    <Box sx={{ 
      maxWidth: '800px',
      width: 'fit-content',
      backgroundColor: '#101010',
      padding: '16px',
      borderRadius: '24px',
    }}>
      {[300, 260, 200].map((width, index) => (
        <Box
          key={index}
          sx={{
            height: '16px',
            width: `${width}px`,
            borderRadius: '16px',
            marginBottom: index === 2 ? 0 : '16px',
            background: `linear-gradient(
              90deg,
              rgba(0, 122, 255, 0.15) 25%,
              rgba(52, 199, 89, 0.25) 50%,
              rgba(0, 122, 255, 0.15) 75%
            )`,
            backgroundSize: '1000px 100%',
            animation: `${shimmer} 2s infinite linear`,
          }}
        />
      ))}
    </Box>
  );
}
