export default class ChatService {
  private baseURL: string;

  constructor({ baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : 'https://solaciumai-production.up.railway.app' } = {}) {
    this.baseURL = baseURL;
  }

  async streamChat(
    message: string,
    history: { query: string; response: string }[],
    onToken: (token: string) => void,
    language: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const encodedHistory = encodeURIComponent(JSON.stringify(history));
      const eventSource = new EventSource(
        `${this.baseURL}/api/chat?message=${encodeURIComponent(message)}&history=${encodedHistory}&language=${language}`,
        { withCredentials: true }
      );

      // Timeout to handle long-running connections
      const timeoutId = setTimeout(() => {
        console.error('SSE connection timed out.');
        eventSource.close();
        reject(new Error('SSE connection timed out.'));
      }, 60000); // 60 seconds

      // Handle messages from the backend
      eventSource.onmessage = (event) => {
        clearTimeout(timeoutId); // Clear timeout when we receive data
        try {
          if (event.data === '[DONE]') {
            eventSource.close();
            resolve();
            return;
          }

          const data = JSON.parse(event.data);
          if (data.token) {
            onToken(data.token);
          } else if (data.error) {
            throw new Error(data.error);
          }
        } catch (error) {
          console.error('Error parsing SSE data:', error);
          eventSource.close();
          reject(error);
        }
      };

      // Handle errors
      eventSource.onerror = (error) => {
        clearTimeout(timeoutId);
        console.error('EventSource error:', error);
        eventSource.close();
        reject(new Error('Failed to establish SSE connection. Please try again later.'));
      };

      // Clean up when the connection is complete
      eventSource.addEventListener('done', () => {
        clearTimeout(timeoutId);
        eventSource.close();
        resolve();
      });
    });
  }
}