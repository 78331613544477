import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiMenu, FiX, FiPlus, FiSettings, FiTrash2 } from 'react-icons/fi';
import { collection, getDocs, query, where, deleteDoc, doc } from "firebase/firestore";
import { db } from '../../firebaseConfig';
import { User } from 'firebase/auth';
import { DeleteModal } from './DeleteModal';

// Add these interfaces at the top of the file
interface ChatMessage {
  query: string;
  response: string;
}

interface ChatDocument {
  id: string;
  userId: string;
  title: string;
  history: ChatMessage[];
  createdAt: {
    toMillis: () => number;
  };
  updatedAt: {
    toMillis: () => number;
  };
}

interface ChatItemProps {
  chat: ChatDocument;
  onLoad: (chatId: string) => void;
  onDelete: (chatId: string) => void;
  isOpen: boolean;
}

const PopupLabel = ({ children, isVisible }: { children: React.ReactNode; isVisible: boolean }) => (
  <AnimatePresence>
    {isVisible && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="absolute left-10 bg-gray-800 text-white px-2 py-1 rounded text-sm whitespace-nowrap"
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
);

const SidebarButton = ({ icon: Icon, label, isOpen, onClick }: { icon: React.ComponentType; label: string; isOpen: boolean, onClick?: () => void }) => {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <div className="relative w-full">
      <button
        className="w-full flex items-center hover:bg-[#303030] p-2 rounded-md focus:outline-none"
        onMouseEnter={() => !isOpen && setShowPopup(true)}
        onMouseLeave={() => setShowPopup(false)}
        onClick={onClick}
      >
        <div className="flex items-center gap-2">
          <Icon />
          {isOpen && <span className="text-sm">{label}</span>}
        </div>
      </button>
      <PopupLabel isVisible={showPopup && !isOpen}>{label}</PopupLabel>
    </div>
  );
};

const ChatItem: React.FC<ChatItemProps> = ({ chat, onLoad, onDelete, isOpen }) => {
  return (
    <div className="flex items-center justify-between hover:bg-[#303030] p-2 rounded-md group">
      <button
        onClick={() => onLoad(chat.id)}
        className="text-sm text-left flex-grow truncate"
      >
        {chat.title || `Chat ${chat.id.slice(-5)}`}
      </button>
      <button
        onClick={(e) => {
          e.stopPropagation();
          onDelete(chat.id);
        }}
        className="text-red-500 hover:text-red-400 opacity-0 group-hover:opacity-100 transition-opacity"
        title="Delete chat"
      >
        <FiTrash2 size={16} />
      </button>
    </div>
  );
};

export const Sidebar = ({ isOpen, toggleSidebar, user, loadChatHistory, startNewChat }: { isOpen: boolean; toggleSidebar: () => void; user: User | null; loadChatHistory: (chatId: string) => void; startNewChat: () => void }) => {
  const [savedChats, setSavedChats] = useState<ChatDocument[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [chatToDelete, setChatToDelete] = useState<string | null>(null);

  const fetchChats = async () => {
    if (!user) return;
    try {
      const chatsRef = collection(db, "chats");
      const q = query(chatsRef, where("userId", "==", user.uid));
      const querySnapshot = await getDocs(q);
      const chats = querySnapshot.docs.map(doc => ({ 
        id: doc.id, 
        ...doc.data() 
      })) as ChatDocument[];
      
      // Sort chats by updatedAt timestamp, most recent first
      chats.sort((a, b) => b.updatedAt.toMillis() - a.updatedAt.toMillis());
      setSavedChats(chats);
    } catch (error) {
      console.error("Error fetching chats:", error);
    }
  };

  const deleteChat = async (chatId: string) => {
    setChatToDelete(chatId);
    setDeleteModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (!user || !chatToDelete) return;
    
    try {
      const chatRef = doc(db, "chats", chatToDelete);
      await deleteDoc(chatRef);
      
      setSavedChats(prevChats => prevChats.filter(chat => chat.id !== chatToDelete));
      startNewChat();
      setDeleteModalOpen(false);
      setChatToDelete(null);
    } catch (error) {
      console.error("Error deleting chat:", error);
      alert('Failed to delete chat. Please try again.');
    }
  };

  // Fetch chats when component mounts and when user changes
  useEffect(() => {
    fetchChats();
    // Set up real-time listener
    const interval = setInterval(fetchChats, 5000); // Refresh every 5 seconds
    
    return () => clearInterval(interval);
  }, [user]);

  return (
    <>
      <motion.div
        initial={false}
        animate={{ width: isOpen ? '16rem' : '4rem', height: isOpen ? 'calc(100vh - 8rem)' : '60px', transition: { duration: 0.3 } }}
        className="bg-[#1a1a1a] text-white px-1 shadow-lg flex flex-col z-10 rounded-2xl overflow-hidden mb-4"
      >
        <motion.div className="flex p-3 justify-center" animate={{ width: "100%", justifyContent: "flex-start" }} transition={{ duration: 0.3 }}>
          <motion.button onClick={toggleSidebar} className="text-xl bg-gray-700 p-2 rounded-md hover:bg-gray-600 focus:outline-none" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            {isOpen ? <FiX /> : <FiMenu />}
          </motion.button>
        </motion.div>

        {isOpen && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }} className="flex flex-col flex-1 pt-4 pb-10">
            <div className="flex-1">
              <SidebarButton icon={FiPlus} label="Start New Chat" isOpen={isOpen} onClick={startNewChat} />

              <div className="ml-3 flex flex-col items-start mt-2">
                <h2 className="text-sm font-bold mb-2">Saved Chats</h2>
                {savedChats.length > 0 ? (
                  <ul className="space-y-2 w-full pr-2">
                    {savedChats.map(chat => (
                      <li key={chat.id}>
                        <ChatItem
                          chat={chat}
                          onLoad={loadChatHistory}
                          onDelete={deleteChat}
                          isOpen={isOpen}
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-sm text-gray-500">No saved chats</p>
                )}
              </div>
            </div>

            <div className="mt-auto">
              <SidebarButton icon={FiSettings} label="Settings" isOpen={isOpen} />
            </div>
          </motion.div>
        )}
      </motion.div>

      <DeleteModal
        isOpen={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
          setChatToDelete(null);
        }}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default Sidebar;