import React, { useState, useRef, useEffect } from 'react';
import { Avatar } from '@radix-ui/react-avatar';
import { FaBell } from 'react-icons/fa'; // Import FontAwesome's bell icon
import { User } from 'firebase/auth';
import md5 from 'md5'; // We use md5 to hash the email for Gravatar
import { LanguageMenu } from './LanguageMenu';

interface HeaderProps {
  user: User | null;
  handleGoogleSignIn: () => Promise<void>;
  handleSignOut: () => Promise<void>;
}

// Generate Gravatar URL based on email
const generateGravatarUrl = (email: string | null) => {
  if (!email) return '';
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`; // Use 'identicon' for default fallback
};

export const Header: React.FC<HeaderProps> = ({ user, handleGoogleSignIn, handleSignOut }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false); // Track if dropdown is open
  const dropdownRef = useRef<HTMLDivElement>(null); // Ref for dropdown to detect outside clicks

  // Toggle the dropdown on avatar click
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  // Fallback function to display user's initial if no photo is available
  const getUserInitial = (name: string | null) => {
    return name ? name.charAt(0).toUpperCase() : '?'; 
  };
  // Check if valid image exists
  const profileImageUrl = user?.photoURL || (user?.email ? generateGravatarUrl(user.email) : null);

  return (
    <header className="z-50 flex w-full justify-between items-center px-6 md:px-10 py-4 text-white shadow-md">
      <h1 className="font-semibold text-xl cursor-pointer hover:text-blue-400" onClick={() => window.location.href = '/'}>Solacium</h1>
      <div className="flex items-center justify-center space-x-4 relative">
        <LanguageMenu />

        {/* Show user avatar and name if signed in */}
        {user ? (
          <div className="relative">
            <div onClick={toggleDropdown} className="flex items-center cursor-pointer space-x-3">
              <p className="hidden sm:inline text-lg">{user.displayName}</p>

              {/* Avatar with fallback for user photo */}
              <Avatar className="rounded-full w-8 h-8 flex items-center justify-center bg-gray-500 text-white">
                {profileImageUrl ? (
                  <img src={profileImageUrl} alt="User avatar" className="rounded-full w-full h-full object-cover" />
                ) : (
                  <span className="text-lg font-semibold">{getUserInitial(user.displayName)}</span>
                )}
              </Avatar>
            </div>

            {/* Dropdown menu */}
            {dropdownOpen && (
              <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-gray-800 shadow-lg rounded-lg">
                <ul className="py-2 px-2">
                  <li
                    className="px-6 py-2 hover:bg-gray-700 hover:rounded-full text-gray-300 cursor-pointer"
                    onClick={handleSignOut}
                  >
                    Sign Out
                  </li>
                </ul>
              </div>
            )}
          </div>
        ) : (
          <button onClick={handleGoogleSignIn} className="text-md text-gray-300 hover:text-white">
            Sign In
          </button>
        )}
      </div>
    </header>
  );
};