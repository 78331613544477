import React from 'react';
import { motion } from 'framer-motion';

interface DeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const DeleteModal: React.FC<DeleteModalProps> = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 backdrop-blur-sm bg-black bg-opacity-60 flex items-center justify-center z-50">
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        className="bg-[#2a2a2a] rounded-lg p-4 w-60 shadow-xl"
      >
        <div className="flex flex-col items-center">
          {/* App Icon */}
          <div className="w-16 h-16 bg-white rounded-xl mb-4 flex items-center justify-center">
            <svg viewBox="0 0 24 24" className="w-10 h-10 text-black">
              <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.88-11.71L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z"/>
            </svg>
          </div>
          
          <h2 className="text-sm font-bold mb-1 text-white">Delete Chat</h2>
          <p className="text-gray-300 text-center mb-2 text-xs">
            This can't be undone.
          </p>
          
          <div className="flex gap-3 w-full text-xs">
            <button
              onClick={onClose}
              className="flex-1 px-3 py-2 bg-gray-600 hover:bg-gray-500 text-white rounded-lg transition-colors"
            >
              Cancel
            </button>
            <button
              onClick={onConfirm}
              className="flex-1 px-3 py-2 bg-gray-600 hover:bg-red-700 text-red-600 hover:text-white rounded-lg transition-colors"
            >
              Delete
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}; 