import React from 'react';
import { FaLanguage, FaChevronDown } from "react-icons/fa";
import { useTranslation } from '../../contexts/TranslationContext';

const languageMap: Record<string, string> = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  pt: 'Português',
  ln: 'Lingala'
};

export const LanguageMenu: React.FC = () => {
  const { language, setLanguage, t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);

  const handleLanguageChange = (newLanguage: string) => {
    setLanguage(newLanguage);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 bg-gradient-to-r from-blue-500 to-green-500 text-white font-bold py-2 px-4 rounded-3xl shadow-lg transition duration-300 ease-in-out hover:from-blue-400 hover:to-green-400"
      >
        <FaLanguage className="text-xl" />
        <span className="hidden sm:inline">{languageMap[language] || 'Language'}</span>
        <FaChevronDown className="text-xl" />
      </button>
      {isOpen && (
        <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 py-2 w-36 bg-gray-800 rounded-md shadow-xl z-20">
          {Object.entries(languageMap).map(([code, name]) => (
            <a
              key={code}
              href="#"
              className="block px-4 py-2 text-sm text-white hover:bg-gray-700 text-center hover:rounded-full mx-2"
              onClick={(e) => {
                e.preventDefault();
                handleLanguageChange(code);
              }}
            >
              {name}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};
