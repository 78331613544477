import { useState, useCallback } from 'react';

const translationCache: Record<string, string> = {};
const cacheKey = (text: string, targetLang: string) => `${text}:${targetLang}`;

export const useTranslateText = () => {
  const [isTranslating, setIsTranslating] = useState(false);
  
  const translateText = useCallback(async (text: string, targetLang: string) => {
    if (targetLang === 'en') return text;
    
    const key = cacheKey(text, targetLang);
    if (translationCache[key]) return translationCache[key];

    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    if (!apiKey) return text;

    setIsTranslating(true);
    try {
      const response = await fetch(
        `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            q: text,
            target: targetLang,
            source: 'en'
          }),
        }
      );
      
      const data = await response.json();
      const translated = data.data?.translations?.[0]?.translatedText || text;
      translationCache[key] = translated;
      return translated;
    } catch (error) {
      console.error('Translation error:', error);
      return text;
    } finally {
      setIsTranslating(false);
    }
  }, []);

  return { translateText, isTranslating };
};
