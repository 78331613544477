import React, { useState, useEffect, useRef } from 'react';
import { SearchInput } from './SearchInput';
import ChatService from '../../services/chatService';
import { User } from 'firebase/auth';
import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import md5 from 'md5';
import '../../App.css';
import CustomSkeleton from './skeleton';
import ReactMarkdown from 'react-markdown';
import { ResourceSection } from './ResourceSection';
import { Sidebar } from './SideBar';
import { useTranslation } from '../../contexts/TranslationContext';
import { FiMenu, FiBook } from 'react-icons/fi';


const translateText = async (text: string, targetLang: string, sourceLang: string): Promise<string> => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  if (!apiKey || !text.trim()) return text;
  
  try {
    const response = await fetch(
      `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          q: text,
          target: targetLang,
          source: sourceLang,
          format: 'text'
        }),
      }
    );
    
    if (!response.ok) throw new Error(`Translation failed: ${response.statusText}`);
    const data = await response.json();
    return data.data?.translations?.[0]?.translatedText || text;
  } catch (error) {
    console.error('Translation error:', error);
    return text;
  }
};

interface MainSectionProps {
  user: User | null;
}

export const MainSection: React.FC<MainSectionProps> = ({ user }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [chatHistory, setChatHistory] = useState<{ query: string; response: string }[]>([]);
  const [hasQueried, setHasQueried] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isResourceOpen, setIsResourceOpen] = useState(false);
  const [currentChatId, setCurrentChatId] = useState<string | null>(null);
  const { language } = useTranslation();

  const profileImageUrl = user?.photoURL || (user?.email ? `https://www.gravatar.com/avatar/${md5(user.email.trim().toLowerCase())}?d=identicon` : null);

  // Function to start a new chat session
  const startNewChat = () => {
    setChatHistory([]);
    setHasQueried(false);
    setSearchQuery('');
    setCurrentChatId(null);
  };

  // Load chat history from Firestore
  const loadChatHistory = async (chatId: string) => {
    if (!user) return;
    try {
      const chatRef = doc(collection(db, "chats"), chatId);
      const chatDoc = await getDoc(chatRef);
      if (chatDoc.exists()) {
        const data = chatDoc.data();
        setChatHistory(data.history || []);
        setCurrentChatId(chatId);
        setHasQueried(true);
      } else {
        console.error("Chat not found");
      }
    } catch (error) {
      console.error("Error loading chat history from Firestore:", error);
    }
  };

  const saveChat = async (newHistory: { query: string; response: string }[]) => {
    if (!user) return;
    
    try {
      const timestamp = new Date();
      if (!currentChatId) {
        // Create new chat
        const chatRef = await addDoc(collection(db, "chats"), {
          userId: user.uid,
          title: newHistory[0].query.substring(0, 30) + "...", // Use first message as title
          history: newHistory,
          createdAt: timestamp,
          updatedAt: timestamp
        });
        setCurrentChatId(chatRef.id);
      } else {
        // Update existing chat
        const chatRef = doc(db, "chats", currentChatId);
        await updateDoc(chatRef, {
          history: newHistory,
          updatedAt: timestamp
        });
      }
    } catch (error) {
      console.error("Error saving chat:", error);
    }
  };

  const handleNewQuery = async (query: string) => {
    setIsLoading(true);

    try {
      const chatService = new ChatService();
      setChatHistory(prev => [...prev, { query, response: "" }]);
      setHasQueried(true);

      let currentResponse = "";
      let buffer = "";

      const contextHistory = chatHistory.slice(-5);

      await chatService.streamChat(
        query,
        contextHistory,
        (token) => {
          buffer += token;

          // Process complete sentences or large chunks
          if (buffer.match(/[.!?]\s*$/) || buffer.length > 200) {
            currentResponse += buffer;
            buffer = "";

            setChatHistory(prev => {
              const updated = [...prev];
              updated[updated.length - 1].response = currentResponse.trim();
              return updated;
            });
          }
        },
        language
      );

      // Handle remaining buffer content
      if (buffer) {
        currentResponse += buffer;
        setChatHistory(prev => {
          const updated = [...prev];
          updated[updated.length - 1].response = currentResponse.trim();
          return updated;
        });
      }

      await saveChat([...chatHistory, { query, response: currentResponse }]);
    } catch (error: any) {
      console.error('Error:', error);
      setChatHistory(prev => {
        const updated = [...prev];
        updated[updated.length - 1].response = "Error: " + error.message;
        return updated;
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Add function to handle sidebar toggle
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    if (!isSidebarOpen) {
      setIsResourceOpen(false); // Close resource section when opening sidebar
    }
  };

  // Add function to handle resource section toggle
  const toggleResource = () => {
    setIsResourceOpen(!isResourceOpen);
    if (!isResourceOpen) {
      setIsSidebarOpen(false); // Close sidebar when opening resource section
    }
  };

  return (
    <main className="relative flex h-screen overflow-hidden w-full">
      {/* Sidebar - Position absolute on mobile */}
      <div className={`absolute z-20 h-full transition-transform duration-300 ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      } md:relative md:translate-x-0`}>
        <Sidebar 
          isOpen={isSidebarOpen} 
          toggleSidebar={toggleSidebar}
          user={user}
          loadChatHistory={loadChatHistory} 
          startNewChat={startNewChat} 
        />
      </div>

      {/* Main content */}
      <div className="flex flex-col justify-between items-center h-full flex-grow px-4 md:px-8 xl:px-16 mx-auto lg:mx-36 w-full md:w-auto">
        {/* Mobile header with toggle buttons */}
        <div className="md:hidden w-full flex justify-between items-center py-2">
          <button 
            onClick={toggleSidebar}
            className="p-2 rounded-md bg-gray-700 hover:bg-gray-600"
          >
            <FiMenu />
          </button>
          <button 
            onClick={toggleResource}
            className="p-2 rounded-md bg-gray-700 hover:bg-gray-600"
          >
            <FiBook />
          </button>
        </div>

        {hasQueried ? (
          <ChatHistory chatHistory={chatHistory} profileImageUrl={profileImageUrl} isLoading={isLoading} />
        ) : (
          <WelcomeSection user={user} />
        )}
        <FooterSection selectedLanguage={language} handleNewQuery={handleNewQuery} />
      </div>

      {/* Resource section - Position absolute on mobile */}
      <div className={`absolute right-0 z-20 h-full transition-transform duration-300 ${
        isResourceOpen ? 'translate-x-0' : 'translate-x-full'
      } md:relative md:translate-x-0`}>
        <ResourceSection 
        searchQuery={searchQuery}
        onClose={() => setIsResourceOpen(false)} // Close the ResourceSection
        />
      </div>

      {/* Overlay for mobile when sidebar or resource section is open */}
      {(isSidebarOpen || isResourceOpen) && (
        <div 
          className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={() => {
            setIsSidebarOpen(false);
            setIsResourceOpen(false);
          }}
        />
      )}
    </main>
  );
};

// Welcome Section
const WelcomeSection: React.FC<{ user: User | null }> = ({ user }) => {
  const { t } = useTranslation();
  return (
    <div className="flex-grow flex flex-col mt-10 items-center text-white">
      <h1 className="text-5xl lg:text-7xl font-bold bg-gradient-to-r from-blue-500 to-green-500 bg-clip-text text-transparent">
        {String(t('welcome'))}, {user?.displayName?.split(' ')[0] || String(t('guest'))}
      </h1>
      <p className="text-2xl font-semibold text-center text-gray-400 mt-2">{String(t('howCanIHelp'))}</p>
      <SuggestionCards />
    </div>
  );
};

// Add this new component above the ChatHistory component
const ScrollToBottom = () => {
  useEffect(() => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  });
  return null;
};

// Modify the ChatHistory component
const ChatHistory: React.FC<{ 
  chatHistory: { query: string; response: string }[]; 
  profileImageUrl: string | null;
  isLoading: boolean;
}> = ({ chatHistory, profileImageUrl, isLoading }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom(); // Automatically scroll when chatHistory changes or loading ends
  }, [chatHistory, isLoading]);

  return (
    <div className="flex-1 w-full overflow-y-auto py-4 space-y-4">
      {chatHistory.map((entry, index) => (
        <ChatEntry 
          key={index} 
          entry={entry} 
          profileImageUrl={profileImageUrl} 
          isLoading={isLoading && index === chatHistory.length - 1} 
        />
      ))}
      {/* Invisible div acts as an anchor for scrolling */}
      <div ref={messagesEndRef} />
    </div>
  );
};

// Chat Entry Component
const ChatEntry: React.FC<{ entry: { query: string, response: string }, profileImageUrl: string | null, isLoading: boolean }> = ({ entry, profileImageUrl, isLoading }) => (
  <div className="flex flex-col gap-4 mb-4">
    <UserMessage message={entry.query} profileImageUrl={profileImageUrl} />
    <AIResponse response={entry.response} isLoading={isLoading} />
  </div>
);

// User Message
const UserMessage: React.FC<{ message: string, profileImageUrl: string | null }> = ({ message, profileImageUrl }) => (
  <div className="flex justify-end items-start">
    <div className="flex bg-[#224b64c6] px-4 py-2 rounded-3xl items-start gap-2">
      <div className=" text-white lg:px-4 max-w-2xl text-end">
        <p>{message}</p>
      </div>
      <ProfileImage profileImageUrl={profileImageUrl} />
    </div>
  </div>
);

// AI Response
const AIResponse: React.FC<{ response: string, isLoading: boolean }> = ({ response, isLoading }) => (
  <div className="flex justify-start items-start">
    <div className="flex items-start bg-[#101010] px-4 py-2 text-white rounded-3xl gap-2">
      <ProfileImage profileImageUrl={null} />
      <div className="lg:px-4 max-w-2xl text-left">
        {isLoading ? (
          <CustomSkeleton />
        ) : (
<ReactMarkdown
  className="leading-relaxed markdown-content"
  components={{
    ol: ({ node, ...props }) => (
      <ol
        style={{
          paddingLeft: '2rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '1.5rem',
        }}
        {...props}
      />
    ),
    li: ({ node, children, ...props }) => {
      const processedChildren = React.Children.toArray(children);
      
      // Find the step title (e.g., "1. Step Title")
      const stepTitleIndex = processedChildren.findIndex(
        (child) => typeof child === 'string' && /^\d+\./.test(child.trim())
      );

      let stepTitle = null;
      let stepContent = processedChildren;

      // Separate the title and content
      if (stepTitleIndex !== -1) {
        stepTitle = processedChildren[stepTitleIndex];
        stepContent = [
          ...processedChildren.slice(0, stepTitleIndex),
          ...processedChildren.slice(stepTitleIndex + 1),
        ];
      }

      return (
        <li
          style={{
            lineHeight: '1.8',
          }}
          {...props}
        >
          {/* Step Title */}
          {stepTitle && (
            <strong
              style={{
                display: 'block',
                color: '#60a5fa',
                marginBottom: '0.5rem',
              }}
            >
              {typeof stepTitle === 'string' ? stepTitle.trim() : stepTitle}
            </strong>
          )}
          {/* Step Content */}
          <div style={{ marginLeft: '1rem' }}>{stepContent}</div>
        </li>
      );
    },
    a: ({ href, children, ...props }) => (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: '#60a5fa',
          borderBottom: '1px dashed #60a5fa',
          transition: 'color 0.2s ease',
        }}
        {...props}
      >
        {children}
      </a>
    ),
    p: ({ node, ...props }) => (
      <p
        style={{
          lineHeight: '1.8',
        }}
        {...props}
      />
    ),
  }}
>
  {response}
</ReactMarkdown>
        )}
      </div>
    </div>
  </div>
);

// Profile Image
const ProfileImage: React.FC<{ profileImageUrl: string | null }> = ({ profileImageUrl }) => (
  <div className="w-8 h-8">
    {profileImageUrl ? (
      <img src={profileImageUrl} alt="User avatar" className="w-full h-full min-w-8 min-h-8 rounded-full" />
    ) : (
      <div className="bg-gray-500 min-w-8 min-h-8 rounded-full w-full h-full flex items-center justify-center text-white">S</div>
    )}
  </div>
);

// Footer Section
const FooterSection: React.FC<{ selectedLanguage: string, handleNewQuery: (query: string) => void }> = ({ selectedLanguage, handleNewQuery }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full mb-10 max-w-3xl">
      <SearchInput selectedLanguage={selectedLanguage} onNewQuery={handleNewQuery} />
      <Disclaimer />
    </div>
  );
};

const SuggestionCards = () => {
  const { t } = useTranslation();
  return (
    <div className="suggestions mt-8 grid grid-cols-2 lg:grid-cols-4 gap-6">
      <SuggestionCard text={String(t('suggestions.attorney'))} icon="⚖️" />
      <SuggestionCard text={String(t('suggestions.asylum'))} icon="🛂" />
      <SuggestionCard text={String(t('suggestions.documents'))} icon="📄" />
      <SuggestionCard text={String(t('suggestions.visa'))} icon="✈️" />
    </div>
  );
};

const Disclaimer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-4 text-sm text-center text-gray-400">
      <p>{String(t('disclaimer'))}</p>
    </div>
  );
};

interface SuggestionCardProps {
  text: string;
  icon: string;
}

const SuggestionCard: React.FC<SuggestionCardProps> = ({ text, icon }) => (
  <div className="p-4 bg-gray-700 text-white rounded-lg shadow-lg flex flex-col items-center justify-between max-w-48 glass cursor-pointer transform hover:scale-105 transition-transform">
    <span className="text-3xl mb-2">{icon}</span>
    <p className="text-center text-sm">{text}</p>
  </div>
);

