import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

export interface NewsItem {
  title: string;
  url: string;
  description: string;
  image_url?: string;
}

interface NewsSlideshowProps {
  news: NewsItem[];
  isCollapsed?: boolean;
  interval?: number;
}

export const NewsSlideshow: React.FC<NewsSlideshowProps> = ({ 
  news, 
  isCollapsed = false, 
  interval = 10000 
}) => {
  const [currentArticleIndex, setCurrentArticleIndex] = useState(0);
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (news.length > 0 && !isCollapsed) {
      const timer = setInterval(() => {
        setCurrentArticleIndex((prevIndex) => 
          prevIndex === news.length - 1 ? 0 : prevIndex + 1
        );
        setKey(prev => prev + 1);
      }, interval);

      return () => clearInterval(timer);
    }
  }, [news.length, interval, isCollapsed]);

  const handleArticleClick = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  if (!news.length) return null;

  return (
    <AnimatePresence mode="wait">
      {!isCollapsed && (
        <motion.div
          key={currentArticleIndex}
          initial={{ x: 300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="mt-2"
        >
          <div 
            className="relative cursor-pointer group"
            onClick={() => handleArticleClick(news[currentArticleIndex].url)}
            role="link"
            aria-label={`Read article: ${news[currentArticleIndex].title}`}
          >
            <div className="relative h-48 w-full">
              {news[currentArticleIndex].image_url ? (
                <img 
                  src={news[currentArticleIndex].image_url} 
                  alt={news[currentArticleIndex].title} 
                  className="w-full h-full rounded-lg object-cover transition-transform duration-300 group-hover:scale-[1.02]"
                />
              ) : (
                <div className="w-full h-full bg-gray-700 rounded-lg flex items-center justify-center">
                  <span className="text-gray-400">No image available</span>
                </div>
              )}
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent rounded-lg" />
              <div className="absolute bottom-3 left-3 right-3 text-sm font-semibold text-white group-hover:text-blue-400">
                {news[currentArticleIndex].title}
              </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0 h-1 bg-gray-700 rounded-b-lg overflow-hidden">
              <motion.div
                key={key}
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                transition={{ duration: interval / 1000, ease: "linear" }}
                className="h-full bg-blue-500 origin-left"
              />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}; 