import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlay, FaCheck, FaGlobe, FaBolt, FaBook } from 'react-icons/fa';
import { AiFillStar } from 'react-icons/ai';
import { BsPeopleFill } from 'react-icons/bs';
import { Header } from './Header';
import { User } from 'firebase/auth';
import { useTranslation } from '../../contexts/TranslationContext';

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description }) => (
  <div className="p-6 bg-gray-800 rounded-xl shadow-xl hover:transform hover:scale-105 transition-all">
    <div className="text-4xl mb-4 text-blue-500">{icon}</div>
    <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
    <p className="text-gray-400">{description}</p>
  </div>
);

interface LandingPageProps {
  user: User | null;
  handleGoogleSignIn: () => Promise<void>;
  handleSignOut: () => Promise<void>;
}

export const LandingPage: React.FC<LandingPageProps> = ({
  user,
  handleGoogleSignIn,
  handleSignOut
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="min-h-screen w-full bg-black text-white">
      <div className="min-h-screen bg-gradient-to-b from-black to-gray-900">
        <Header 
          user={user}
          handleGoogleSignIn={handleGoogleSignIn}
          handleSignOut={handleSignOut}
        />
        
        <div className="container mx-auto p-6">
          <div className="flex flex-col lg:flex-row items-center justify-between gap-12 mb-10">
            <div className="flex-1 lg:max-w-xl">
              <h1 className="text-5xl lg:text-7xl font-bold mb-6 bg-gradient-to-r from-blue-500 to-green-500 bg-clip-text text-transparent">
                {t('landing.title')}
              </h1>
              <p className="text-xl text-gray-300 mb-8">
                {t('landing.subtitle')}
              </p>
              <div className="space-y-6">
                <button
                  onClick={() => user ? navigate('/chat') : handleGoogleSignIn()}
                  className="w-full sm:w-auto px-8 py-4 bg-gradient-to-r from-blue-500 to-green-600 hover:bg-blue-700 text-white rounded-full text-lg font-semibold transition-all transform hover:scale-105 shadow-lg animate-pulse"
                >
                  {user ? t('buttons.startJourney') : t('buttons.signIn')}
                </button>
                <div className="flex items-center gap-4 text-gray-400">
                  <div className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    {t('landing.noCard')}
                  </div>
                  <div className="flex items-center">
                    <FaCheck className="text-green-500 mr-2" />
                    {t('landing.instantAccess')}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 w-full lg:max-w-xl">
              <div className="relative aspect-video w-full bg-gray-800 rounded-xl overflow-hidden shadow-2xl border-2 border-gray-700">
                <div className="absolute inset-0 flex items-center justify-center bg-gradient-to-br from-gray-800 to-gray-900">
                  <div className="text-center">
                    <div className="text-6xl mb-4 flex justify-center">
                      <FaPlay className="text-blue-500" />
                    </div>
                    <p className="text-gray-400 text-lg font-medium">{t('landing.watchDemo')}</p>
                    <p className="text-gray-500 text-sm mt-2">{t('landing.demoLength')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center text-white mb-8">
            <p className="text-gray-400 mb-4">{t('trust.trusted')}</p>
            <div className="flex justify-center text-lg lg:text-2xl gap-8">
              <span className=" flex items-center">
                <AiFillStar className="text-yellow-400 mr-2" /> {t('trust.rating')}
              </span>
              <span className="flex items-center">
                <BsPeopleFill className="text-blue-400 mr-2" /> {t('trust.users')}
              </span>
              <span className="flex items-center">
                <FaGlobe className="text-green-400 mr-2" /> {t('trust.support')}
              </span>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <FeatureCard
              icon={<FaGlobe />}
              title={t('features.multiLanguage')}
              description={t('features.multiLanguageDesc')}
            />
            <FeatureCard
              icon={<FaBolt />}
              title={t('features.instantAnswers')}
              description={t('features.instantAnswersDesc')}
            />
            <FeatureCard
              icon={<FaBook />}
              title={t('features.resources')}
              description={t('features.resourcesDesc')}
            />
          </div>
        </div>
      </div>
    </div>
  );
}; 