import React, { createContext, useState, useEffect, useContext } from 'react';
import { translations } from '../translations/Translations';
import { useTranslateText } from '../hooks/TranslateText';

interface TranslationContextType {
  language: string;
  setLanguage: (lang: string) => void;
  translations: typeof translations;
  translateDynamic: (text: string) => Promise<string>;
  t: (key: string, params?: Record<string, string>) => string;
}

const TranslationContext = createContext<TranslationContextType | null>(null);

export const TranslationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const { translateText } = useTranslateText();

  const translateDynamic = async (text: string): Promise<string> => {
    if (language === 'en') return text;
    return await translateText(text, language);
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem('preferred-language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('preferred-language', language);
    document.documentElement.lang = language;
  }, [language]);

  const t = (key: string, params?: Record<string, string>): string => {
    const keys = key.split('.');
    let currentLang = language as keyof typeof translations;
    let result: any = translations[currentLang];
    
    // Try to get translation in current language
    for (const k of keys) {
      result = result?.[k];
      if (result === undefined) break;
    }

    // If not found, try English
    if (result === undefined) {
      result = translations.en;
      for (const k of keys) {
        result = result?.[k];
        if (result === undefined) break;
      }
    }

    // If still not found or not a string, return the key
    if (typeof result !== 'string') {
      return key;
    }

    // Handle parameter replacement
    if (params) {
      Object.entries(params).forEach(([paramKey, value]) => {
        result = result.replace(`{{${paramKey}}}`, value);
      });
    }

    return result;
  };

  const value = {
    language,
    setLanguage,
    translations,
    translateDynamic,
    t
  };

  return (
    <TranslationContext.Provider value={value}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = () => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }
  return context;
}; 