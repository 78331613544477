// src/services/newsService.ts
import axios from 'axios';

export const fetchNewsArticles = async (
  query: string,
  country?: string,
  category?: string
) => {
  const apiKey = process.env.REACT_APP_NEWSDATA_API_KEY;
  
  if (!apiKey) {
    console.error("API key is not configured");
    return [];
  }

  try {
    const response = await axios.get(`https://newsdata.io/api/1/news`, {
      params: {
        apikey: apiKey,
        q: query,
        country: country || '',
        category: category || '',
        language: 'en',
      },
    });
    return response.data.results;
  } catch (error) {
    console.error("Error fetching news articles:", error);
    return [];
  }
};