import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Header } from './frontend/components/Header';
import { MainSection } from './frontend/components/MainSection';
import { LandingPage } from './frontend/components/LandingPage';
import { motion } from 'framer-motion';
import ConfettiExplosion from 'react-confetti-explosion';
import { auth, provider } from './firebaseConfig';
import { signInWithPopup, onAuthStateChanged, signOut, User } from 'firebase/auth';
import { TranslationProvider } from './contexts/TranslationContext';

// Create a wrapper component for the main application layout
const MainLayout: React.FC<{
  user: User | null;
  handleGoogleSignIn: () => Promise<void>;
  handleSignOut: () => Promise<void>;
}> = ({ user, handleGoogleSignIn, handleSignOut }) => {
  const navigate = useNavigate();

  // Redirect to landing page if not logged in
  useEffect(() => {
    if (!user) {
      navigate('/');
    }
  }, [user, navigate]);

  return (
    <div className="relative bg-black">
      <motion.div
        initial={{ marginLeft: '0' }}
        animate={{

        }}
        transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        className="flex flex-col h-screen bg-black text-white"
      >
        <Header user={user} handleGoogleSignIn={handleGoogleSignIn} handleSignOut={handleSignOut} />
        <MainSection user={user} />
      </motion.div>
    </div>
  );
};

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [popupMessage, setPopupMessage] = useState<string | null>(null);
  const [isExploding, setIsExploding] = useState(false);

  const explosionProps = {
    force: 0.4,
    duration: 2200,
    particleCount: 30,
    width: 400,
  };

  // Google Sign-In
  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      setPopupMessage('Sign-in successful! Welcome');
      setIsExploding(true);
      
      setTimeout(() => {
        setIsExploding(false);
        setPopupMessage(null);
      }, explosionProps.duration);
    } catch (error) {
      console.error('Error during Google sign-in:', error);
      setPopupMessage('Sign-in failed. Please try again.');
      setTimeout(() => setPopupMessage(null), 3000);
    }
  };

  // Sign out
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setPopupMessage('Signed out successfully.');
      setTimeout(() => setPopupMessage(null), 3000);
    } catch (error) {
      console.error('Error during sign out:', error);
      setPopupMessage('Sign-out failed. Please try again.');
      setTimeout(() => setPopupMessage(null), 3000);
    }
  };

  // Check if user is already signed in on page load
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  return (
    <TranslationProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<LandingPage user={user} handleGoogleSignIn={handleGoogleSignIn} handleSignOut={handleSignOut} />} />
            <Route path="/chat" element={<MainLayout user={user} handleGoogleSignIn={handleGoogleSignIn} handleSignOut={handleSignOut} />} />
          </Routes>

          {/* Toast and Confetti */}
          {popupMessage && (
            <div className="fixed top-4 left-1/2 transform -translate-x-1/2 p-4 bg-green-500 text-white rounded-full shadow-lg flex items-center justify-center">
              <p>{popupMessage}</p>
              {isExploding && (
                <div className="absolute">
                  <ConfettiExplosion {...explosionProps} />
                </div>
              )}
            </div>
          )}
        </div>
      </Router>
    </TranslationProvider>
  );
};

export default App;
