import React, { useState, useRef } from 'react';
import { useTranslation } from '../../contexts/TranslationContext';

interface SearchInputProps {
  selectedLanguage: string;
  onNewQuery: (query: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({ selectedLanguage, onNewQuery }) => {
  const [query, setQuery] = useState('');
  const [isTranslating, setIsTranslating] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!query.trim()) return;

    setIsTranslating(true);
    try {
      await onNewQuery(query);
      setQuery('');
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }
    } finally {
      setIsTranslating(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuery(e.target.value);
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 208)}px`;
    }
  };

  return (
    <form onSubmit={handleSubmit} className="relative w-full max-w-3xl mx-auto">
      <div className="relative bg-[#1a1a1a] text-white rounded-3xl">
        <textarea
          ref={textareaRef}
          className="flex-1 p-4 pr-16 lg:ml-8 bg-transparent border-none resize-none focus:outline-none w-full flex items-center"
          placeholder={isTranslating ? t('loading') : t('typeMessage')}
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          rows={1}
          disabled={isTranslating}
          style={{ 
            overflowY: 'auto', 
            maxHeight: '208px',
            lineHeight: '24px',
            paddingTop: query ? '16px' : 'calc(24px - 7px)',
          }}
        />
        <button 
          type="submit" 
          className="absolute bottom-3 right-6 py-1 px-2 text-xl text-white rounded-full hover:bg-blue-600"
          disabled={isTranslating}
        >
          {isTranslating ? "..." : "➤"}
        </button>
      </div>
    </form>
  );
};