// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Correctly import getFirestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDKggm2lAr94nh5y9-vqZeLMLPWRDInUfI",
  authDomain: "solacium-ai.firebaseapp.com",
  projectId: "solacium-ai",
  storageBucket: "solacium-ai.appspot.com",
  messagingSenderId: "732115277199",
  appId: "1:732115277199:web:5b86c48a6308c2664f67fa",
  measurementId: "G-JDTSSNSLN7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize and export Firestore, Auth, and Provider
export const db = getFirestore(app); // Correctly initialize Firestore
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();