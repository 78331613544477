export const translations = {
    en: {
      // General
      welcome: "Welcome",
      guest: "Guest",
      loading: "Loading...",
      error: {
        general: "Something went wrong. Please try again.",
        translation: "Translation failed. Showing original text.",
        connection: "Connection error. Please check your internet.",
        auth: "Authentication error. Please sign in again.",
        notFound: "Not found",
        serverError: "Server error. Please try again later."
      },
  
      // Navigation & Buttons
      buttons: {
        signIn: "Sign in with Google",
        signOut: "Sign Out",
        startJourney: "Start Your Journey →",
        send: "Send",
        newChat: "New Chat",
        tryAgain: "Try Again",
        cancel: "Cancel",
        save: "Save",
        delete: "Delete",
        edit: "Edit",
        show: "Show",
        hide: "Hide"
      },
  
      // Main Content
      howCanIHelp: "How can I help you today?",
      typeMessage: "Type your message here...",
      thinking: "AI is thinking...",
  
      // Features Section
      features: {
        multiLanguage: "Multi-language Support",
        multiLanguageDesc: "Get assistance in your preferred language with our real-time translation feature",
        instantAnswers: "Instant Answers",
        instantAnswersDesc: "Receive immediate responses to your immigration-related questions",
        resources: "Resource Library",
        resourcesDesc: "Access a comprehensive collection of immigration resources and guides"
      },
      landing: {
        title: "SolaciumAI: Your Immigration Journey Made Simple",
        subtitle: "Get instant answers to your immigration questions and access reliable resources with our AI-powered assistant.",
        noCard: "No Credit Card Required",
        instantAccess: "Instant Access",
        watchDemo: "Watch How It Works",
        demoLength: "2-minute demo"
      },
  
      // Suggestions
      suggestions: {
        attorney: "Where can I find the best SIJ attorney?",
        asylum: "How do I apply for asylum?",
        documents: "What documents do I need for my immigration case?",
        visa: "Can I get help with my visa application?"
      },
  
      // Trust Indicators
      trust: {
        rating: "Rating",
        users: "Users",
        support: "Global Support",
        trusted: "Trusted by immigration seekers Nationwide"
      },
  
      // Legal & Info
      disclaimer: "Disclaimer: This AI assistant provides general information and is not a substitute for professional legal advice.",
      privacy: "Privacy Policy",
      terms: "Terms of Service",
      cookies: "This website uses cookies to enhance your experience.",
  
      // Chat Interface
      chat: {
        newMessage: "New message",
        reply: "AI Assistant is replying...",
        contextual: "Based on previous messages...",
        clear: "Clear chat",
        save: "Save chat",
        history: "Chat History",
        noHistory: "No chat history found"
      },
  
      // News
      news: {
        latest: "Latest Immigration News"
      },
  
      // Resources
      resources: {
        title: "Immigration Resources",
        uscis: {
          title: "USCIS Official Site",
          description: "Official government immigration services and information"
        },
        legal: {
          title: "Find Legal Help",
          description: "Directory of free or low-cost immigration legal services"
        },
        asylum: {
          title: "Asylum Information",
          description: "Learn about the asylum application process"
        },
        rights: {
          title: "Know Your Rights",
          description: "Essential rights information for immigrants"
        }
      }
    },
  
    es: {
      // General
      welcome: "Bienvenido",
      guest: "Invitado",
      loading: "Cargando...",
      error: {
        general: "Algo salió mal. Por favor, inténtalo de nuevo.",
        translation: "La traducción falló. Mostrando texto original.",
        connection: "Error de conexión. Verifica tu internet.",
        auth: "Error de autenticación. Por favor, inicia sesión de nuevo.",
        notFound: "No encontrado",
        serverError: "Error del servidor. Por favor, inténtalo más tarde."
      },
  
      // Navigation & Buttons
      buttons: {
        signIn: "Iniciar sesión con Google",
        signOut: "Cerrar sesión",
        startJourney: "Comienza tu viaje →",
        send: "Enviar",
        newChat: "Nuevo chat",
        tryAgain: "Inténtalo de nuevo",
        cancel: "Cancelar",
        save: "Guardar",
        delete: "Eliminar",
        edit: "Editar",
        show: "Mostrar",
        hide: "Ocultar"
      },
  
      // Main Content
      howCanIHelp: "¿Cómo puedo ayudarte hoy?",
      typeMessage: "Escribe tu mensaje aquí...",
      thinking: "La IA está pensando...",
  
      // Features Section
      features: {
        multiLanguage: "Soporte multilingüe",
        multiLanguageDesc: "Obtén ayuda en tu idioma preferido con nuestra función de traducción en tiempo real",
        instantAnswers: "Respuestas instantáneas",
        instantAnswersDesc: "Recibe respuestas inmediatas a tus preguntas relacionadas con la inmigración",
        resources: "Biblioteca de recursos",
        resourcesDesc: "Accede a una colección completa de recursos y guías sobre inmigración"
      },
      landing: {
        title: "SolaciumAI: Tu Viaje de Inmigración Simplificado",
        subtitle: "Obtén respuestas instantáneas a tus preguntas de inmigración y accede a recursos confiables con nuestro asistente impulsado por IA.",
        noCard: "No se requiere tarjeta de crédito",
        instantAccess: "Acceso instantáneo",
        watchDemo: "Mira cómo funciona",
        demoLength: "Demostración de 2 minutos"
      },
  
      // Suggestions
      suggestions: {
        attorney: "¿Dónde puedo encontrar al mejor abogado de SIJ?",
        asylum: "¿Cómo solicito asilo?",
        documents: "¿Qué documentos necesito para mi caso de inmigración?",
        visa: "¿Puedo obtener ayuda con mi solicitud de visa?"
      },
  
      // Trust Indicators
      trust: {
        rating: "Calificación",
        users: "Usuarios",
        support: "Soporte global",
        trusted: "Confiado por buscadores de inmigración en todo el mundo"
      },
  
      // Legal & Info
      disclaimer: "Aviso: Este asistente de IA proporciona información general y no sustituye el asesoramiento legal profesional.",
      privacy: "Política de privacidad",
      terms: "Términos de servicio",
      cookies: "Este sitio web utiliza cookies para mejorar tu experiencia.",
  
      // Chat Interface
      chat: {
        newMessage: "Nuevo mensaje",
        reply: "El asistente de IA está respondiendo...",
        contextual: "Basado en mensajes anteriores...",
        clear: "Borrar chat",
        save: "Guardar chat",
        history: "Historial de chat",
        noHistory: "No se encontró historial de chat"
      },
  
      // News
      news: {
        latest: "Noticias de Inmigración Recientes"
      },
  
      // Resources
      resources: {
        title: "Recursos de Inmigración",
        uscis: {
          title: "Sitio Oficial de USCIS",
          description: "Servicios y información oficiales del gobierno sobre inmigración"
        },
        legal: {
          title: "Encuentra Ayuda Legal",
          description: "Directorio de servicios legales gratuitos o de bajo costo sobre inmigración"
        },
        asylum: {
          title: "Información sobre Asilo",
          description: "Aprende sobre el proceso de solicitud de asilo"
        },
        rights: {
          title: "Conoce Tus Derechos",
          description: "Información esencial sobre derechos de inmigrantes"
        }
      }
    },
  
    fr: {
      // General
      welcome: "Bienvenue",
      guest: "Invité",
      loading: "Chargement...",
      error: {
        general: "Une erreur s'est produite. Veuillez réessayer.",
        translation: "La traduction a échoué. Affichage du texte original.",
        connection: "Erreur de connexion. Vérifiez votre internet.",
        auth: "Erreur d'authentification. Veuillez vous reconnecter.",
        notFound: "Non trouvé",
        serverError: "Erreur serveur. Veuillez réessayer plus tard."
      },
  
      buttons: {
        signIn: "Se connecter avec Google",
        signOut: "Se déconnecter",
        startJourney: "Commencez votre voyage →",
        send: "Envoyer",
        newChat: "Nouveau chat",
        tryAgain: "Réessayer",
        cancel: "Annuler",
        save: "Sauvegarder",
        delete: "Supprimer",
        edit: "Éditer",
        show: "Afficher",
        hide: "Masquer"
      },
    // Main Content
    howCanIHelp: "Comment puis-je vous aider aujourd'hui ?",
    typeMessage: "Tapez votre message ici...",
    thinking: "L'IA réfléchit...",

    // Features Section
    features: {
      multiLanguage: "Support multilingue",
      multiLanguageDesc: "Obtenez de l'aide dans votre langue préférée grâce à notre fonctionnalité de traduction en temps réel",
      instantAnswers: "Réponses instantanées",
      instantAnswersDesc: "Recevez des réponses immédiates à vos questions sur l'immigration",
      resources: "Bibliothèque de ressources",
      resourcesDesc: "Accédez à une collection complète de ressources et de guides sur l'immigration"
    },
    landing: {
      title: "SolaciumAI : Simplifiez Votre Parcours d'Immigration",
      subtitle: "Obtenez des réponses instantanées à vos questions sur l'immigration et accédez à des ressources fiables grâce à notre assistant alimenté par IA.",
      noCard: "Aucune carte de crédit requise",
      instantAccess: "Accès instantané",
      watchDemo: "Regardez comment ça fonctionne",
      demoLength: "Démo de 2 minutes"
    },

    // Suggestions
    suggestions: {
      attorney: "Où puis-je trouver le meilleur avocat SIJ ?",
      asylum: "Comment demander l'asile ?",
      documents: "Quels documents dois-je fournir pour mon dossier d'immigration ?",
      visa: "Puis-je obtenir de l'aide pour ma demande de visa ?"
    },

    // Trust Indicators
    trust: {
      rating: "Évaluation",
      users: "Utilisateurs",
      support: "Support global",
      trusted: "Fait confiance aux demandeurs d'immigration dans le monde entier"
    },

    // Legal & Info
    disclaimer: "Avertissement : Cet assistant IA fournit des informations générales et ne remplace pas les conseils juridiques professionnels.",
    privacy: "Politique de confidentialité",
    terms: "Conditions d'utilisation",
    cookies: "Ce site web utilise des cookies pour améliorer votre expérience.",

    // Chat Interface
    chat: {
      newMessage: "Nouveau message",
      reply: "L'assistant IA répond...",
      contextual: "Basé sur les messages précédents...",
      clear: "Effacer le chat",
      save: "Sauvegarder le chat",
      history: "Historique des chats",
      noHistory: "Aucun historique de chat trouvé"
    },
  
    // News
    news: {
      latest: "Dernières Nouvelles sur l'Immigration"
    },
  
    // Resources
    resources: {
      title: "Ressources sur l'Immigration",
      uscis: {
        title: "Site Officiel de USCIS",
        description: "Services et informations officielles du gouvernement sur l'immigration"
      },
      legal: {
        title: "Trouver de l'Aide Juridique",
        description: "Répertoire de services juridiques gratuits ou de faible coût sur l'immigration"
      },
      asylum: {
        title: "Informations sur l'Asile",
        description: "Découvrez le processus d'application pour l'asile"
      },
      rights: {
        title: "Connaître Vos Droits",
        description: "Informations essentielles sur les droits des immigrants"
      }
    }
  },
  pt: {
    // General
    welcome: "Bem-vindo",
    guest: "Convidado",
    loading: "Carregando...",
    error: {
      general: "Algo deu errado. Por favor, tente novamente.",
      translation: "A tradução falhou. Mostrando texto original.",
      connection: "Erro de conexão. Verifique sua internet.",
      auth: "Erro de autenticação. Por favor, faça login novamente.",
      notFound: "Não encontrado",
      serverError: "Erro do servidor. Tente novamente mais tarde."
    },

    // Navigation & Buttons
    buttons: {
      signIn: "Entrar com Google",
      signOut: "Sair",
      startJourney: "Inicie sua jornada →",
      send: "Enviar",
      newChat: "Novo chat",
      tryAgain: "Tente novamente",
      cancel: "Cancelar",
      save: "Salvar",
      delete: "Excluir",
      edit: "Editar",
      show: "Mostrar",
      hide: "Ocultar"
    },

    // Main Content
    howCanIHelp: "Como posso ajudar você hoje?",
    typeMessage: "Digite sua mensagem aqui...",
    thinking: "A IA está pensando...",

    // Features Section
    features: {
      multiLanguage: "Suporte multilíngue",
      multiLanguageDesc: "Obtenha assistência no idioma de sua preferência com nossa função de tradução em tempo real",
      instantAnswers: "Respostas instantâneas",
      instantAnswersDesc: "Receba respostas imediatas para suas perguntas sobre imigração",
      resources: "Biblioteca de recursos",
      resourcesDesc: "Acesse uma coleção completa de recursos e guias de imigração"
    },
    landing: {
      title: "SolaciumAI: Sua Jornada de Imigração Simplificada",
      subtitle: "Obtenha respostas instantâneas para suas perguntas sobre imigração e acesse recursos confiáveis com nosso assistente com tecnologia de IA.",
      noCard: "Cartão de crédito não necessário",
      instantAccess: "Acesso instantâneo",
      watchDemo: "Veja como funciona",
      demoLength: "Demonstração de 2 minutos"
    },

    // Suggestions
    suggestions: {
      attorney: "Onde posso encontrar o melhor advogado de SIJ?",
      asylum: "Como faço para solicitar asilo?",
      documents: "Quais documentos eu preciso para meu caso de imigração?",
      visa: "Posso obter ajuda com minha solicitação de visto?"
    },

    // Trust Indicators
    trust: {
      rating: "Classificação",
      users: "Usuários",
      support: "Suporte global",
      trusted: "Confiado por pessoas que buscam imigração em todo o mundo"
    },

    // Legal & Info
    disclaimer: "Aviso: Este assistente de IA fornece informações gerais e não substitui o aconselhamento jurídico profissional.",
    privacy: "Política de Privacidade",
    terms: "Termos de Serviço",
    cookies: "Este site utiliza cookies para melhorar sua experiência.",

    // Chat Interface
    chat: {
      newMessage: "Nova mensagem",
      reply: "O assistente de IA está respondendo...",
      contextual: "Com base em mensagens anteriores...",
      clear: "Limpar chat",
      save: "Salvar chat",
      history: "Histórico de chat",
      noHistory: "Nenhum histórico de chat encontrado"
    },
  
    // News
    news: {
      latest: "Notícias de Imigração Recentes"
    },
  
    // Resources
    resources: {
      title: "Recursos de Imigração",
      uscis: {
        title: "Site Oficial do USCIS",
        description: "Serviços e informações oficiais do governo sobre imigração"
      },
      legal: {
        title: "Encontre Ajuda Jurídica",
        description: "Diretório de serviços jurídicos gratuitos ou de baixo custo sobre imigração"
      },
      asylum: {
        title: "Informações sobre Asilo",
        description: "Saiba mais sobre o processo de pedido de asilo"
      },
      rights: {
        title: "Conheça Seus Direitos",
        description: "Informações essenciais sobre direitos dos imigrantes"
      }
    }
  },
  ln: {
    // General
    welcome: "Boyei malamu",
    guest: "Mopaya",
    loading: "Ezali kofungwama...",
    error: {
      general: "Eloko moko ekendeki mabe. Tosengi bolimbisi, meka lisusu.",
      translation: "Bobongoli ekoki te. Tozali kolakisa tekste ya ebandeli.",
      connection: "Bokutani elongi te. Tala internet na yo.",
      auth: "Bokutani elongi te. Tosengi okota lisusu.",
      notFound: "Emonani te",
      serverError: "Serveur ezali na problème. Tosengi omeka na sima."
    },

    // Navigation & Buttons
    buttons: {
      signIn: "Kota na Google",
      signOut: "Kobima",
      startJourney: "Bandela mobembo na yo →",
      send: "Tinda",
      newChat: "Sango ya sika",
      tryAgain: "Meka lisusu",
      cancel: "Kolongola",
      save: "Bomba",
      delete: "Kolongola",
      edit: "Bongisa",
      show: "Ndenge",
      hide: "Mopaya"
    },

    // Main Content
    howCanIHelp: "Ndenge nini nakoki kosalisa yo lelo?",
    typeMessage: "Komá nsango na yo awa...",
    thinking: "IA ezali kokanisa...",

    // Features Section
    features: {
      multiLanguage: "Liposho na minoko mingi",
      multiLanguageDesc: "Zwá lisungi na monoko oyo olingaka na bokomeli ya ntango nyonso",
      instantAnswers: "Eyano ya mbala moko",
      instantAnswersDesc: "Zwá eyano mbala moko mpo na mituna na yo na likambo ya immigration",
      resources: "Makomi ya liboso",
      resourcesDesc: "Zwá mikanda ya lisalisi mpe malako mpo na immigration"
    },
    landing: {
      title: "SolaciumAI: Mobembo na yo ya Immigration Ezali Pete",
      subtitle: "Zwá eyano mbala moko na mituna na yo ya immigration mpe zwá mikanda ya motema-sika na lisungi ya moyekoli ya IA.",
      noCard: "Kart ya crédit ezali te na ntina",
      instantAccess: "Kozwa yango mbala moko",
      watchDemo: "Talá ndenge esalaka",
      demoLength: "Demo ya miniti 2"
    },

    // Suggestions
    suggestions: {
      attorney: "Epai nakoki kozwa avocat malamu ya SIJ?",
      asylum: "Ndenge nini nakoki kosenga asile?",
      documents: "Makomi nini nakoki komema mpo na case na ngai ya immigration?",
      visa: "Nakoki kozwa lisalisi mpo na komema visa?"
    },

    // Trust Indicators
    trust: {
      rating: "Ntalo",
      users: "Bato oyo basalelaka",
      support: "Lisungi ya mokili mobimba",
      trusted: "Elikia na immigration ezali na mokili mobimba"
    },

    // Legal & Info
    disclaimer: "Bokebisi: Moyekoli ya IA ezali kopesa kaka mayele mpe ezali te esika ya bokambi ya molóngani.",
    privacy: "Makoki ya nzoto",
    terms: "Mibeko ya mosala",
    cookies: "Website oyo ezali kosalela cookies mpo na kobongisa yango.",
  
    // News
    news: {
      latest: "Boyei malamu ya Immigration"
    },
  
    // Resources
    resources: {
      title: "Makomi ya Immigration",
      uscis: {
        title: "Website oyo USCIS",
        description: "Services ya USCIS ya government ya kobongisa"
      },
      legal: {
        title: "Kozwa Ajuda ya Kolo",
        description: "Diretório ya mikanda ya kolo ya kobongisa"
      },
      asylum: {
        title: "Makomi ya Asilo",
        description: "Makomi ya kozwa asilo"
      },
      rights: {
        title: "Makomi ya Droit",
        description: "Makomi ya kozwa droit"
      }
    }
  }
};  